/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PromoBanner {
    --content-wrapper-padding: 32px;
    --content-wrapper-padding-mobile: 14px;

    .Image-Image {
        object-fit: cover;
    }

    &-Content {
        @media (max-width: 1170px) {
            padding: var(--content-wrapper-padding);
        }

        @media (min-width: 1171px) {
            &::before {
                display: block;
                content: "";
                width: 415px;
                height: 475px;
                position: absolute;
                bottom: 150px;
                right: 200px;
                background: url($path-img + 'circle-white.svg') no-repeat center;
                background-size: 100% 100%;
            }
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-block-end: 100px;
        }

        @include mobile {
            padding: var(--content-wrapper-padding-mobile);
            padding-block-end: 25px;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            z-index: 10;
            margin-bottom: 20px;

            .Button {
                width: auto;
            }

            &_hasShadow {
                .PromoBanner-Title {
                    text-shadow: 0 0 12px rgba(0,0,0,.75);
                }

                .PromoBanner-Description {
                    text-shadow: 0 0 5px rgba(0,0,0,.75);
                }
            }
        }
    }

    &-PlayButtonWrapper {
        @include mobile {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-block: 33% 10px;
        }
    }

    &-PlayButton {
        --promo-banner-play-btn-size: 66px;

        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        span {
            color: var(--primary-dark-color);
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;

            @media (max-width: 900px) {
                display: none;
            }
        }

        &::before {
            content: "";
            display: block;
            width: var(--promo-banner-play-btn-size);
            height: var(--promo-banner-play-btn-size);
            background: url($path-img + 'play.svg') no-repeat center;
            background-size: 100% 100%;

            @include desktop {
                --promo-banner-play-btn-size: 93px;
            }
        }

        @include desktop {
            position: absolute;
            bottom: 70px;
            right: -180px;
            z-index: 100;
            width: 220px;

            &::before {
                transition: all .3s ease;
            }

            &:hover {
                &::before {
                    transform: scale(1.05);
                }
            }
        }

        @media (min-width: 1800px) {
            bottom: 135px;
        }
    }

    &-Link {
        order: 3;

        @include desktop {
            margin-top: 50px;
        }

        @include mobile {
            margin-bottom: 20px;
        }

        .Button {
            --button-border: var(--button-background);

            @include buttonWithArrow();

            @include mobile {
                --button-padding: 30px;
                --button-hover-padding: 30px;
            }
        }
    }

    &-Description {
        z-index: 10;
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 15px;
        line-height: 1.6;
        text-align: center;

        &_large {
            width: 90%;
            max-width: inherit;
        }

        @include mobile {
            color: var(--title-color-mobile, #{$white});
        }

        @include desktop {
            order: -1;
            max-width: 420px;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 3px;
            text-transform: uppercase;
            text-align: start;
        }
    }

    &-Title {
        font-weight: 800;
        text-transform: uppercase;
        z-index: 1;

        @include mobile {
            margin-block-end: 0.3em;
            font-size: clamp(28px,calc(2.76vw + 17.66px), 40px); // from 375 to 810
            color: var(--title-color-mobile, #{$white});
            text-align: center;
        }

        @include desktop {
            font-size: clamp(35px, calc(3.33vw + 8px), 50px);
        }

        @media (min-width: 1171px) {
            max-width: 580px;

            &_large {
                width: 90%;
                max-width: inherit;
            }
        }
    }

    &-Figure {
        @include mobile {
            &.PromoBanner-Figure_hasGradient {
                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                    z-index: 1;
                }
            }

            &::after {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 5px;
                background: $white;
                z-index: 2;
            }
        }

        .Image {
            display: block;
            aspect-ratio: 330 / 575;

            @include mobile {
                aspect-ratio: 270 / 360;
            }

            @media (min-width: 811px) {
                aspect-ratio: 380 / 750;
            }

            @media (min-width: 900px) {
                aspect-ratio: 380 / 650;
            }

            @media (min-width: 1100px) {
                aspect-ratio: 380 / 550;
            }

            @media (min-width: 1470px) {
                aspect-ratio: 380 / 450;
            }

            @media (min-width: 1620px) {
                aspect-ratio: 816 / 852;
            }
        }
    }

    &-FigureSecondary {
        width: 50%; // @TODO
        position: absolute;
        right: 0;
        top: calc(50% - 50px);
        transform: translateY(-50%);

        @media (max-width: 1640px) {
            top: 50%;
            right: -100px;
        }

        @media (max-width: 1340px) {
            right: -200px;
        }

        @media (max-width: 1170px) {
            display: none;
        }

        .Image {
            aspect-ratio: 463 / 519;
        }
    }

    &-Padding {
        .PromoBanner-Figure {
            opacity: 0;
        }

        @media (max-width: 1170px) {
            display: none;
        }
    }

    &-Inner {
        position: relative;

        @include mobile {
            padding-bottom: 50px;
        }

        &_firstVisit { // Slider first visit
            .PromoBanner-Description {
                display: block;
                width: 475px;
                max-width: 100%;
                margin-top: 10px;
                font-weight: 300;
                font-size: 21px;
                color: var(--default-color);
                letter-spacing: 0;
                line-height: 1.4;
                text-transform: inherit;

                @include mobile {
                    font-size: 15px;
                    text-align: start;
                }
            }

            @include mobile {
                .PromoBanner {
                    &-Content {
                        position: relative;
                        top: inherit;
                        display: inherit;
                    }

                    &-Title {
                        width: calc(100% - 75px);
                        margin-top: -42px;
                        font-size: clamp(35px, calc(1.15vw + 30.69px), 40px); // from 375 to 810
                        color: var(--default-color);
                        text-align: left;
                    }

                    &-Figure {
                        &::after {
                            @include mobile {
                                display: none;
                            }
                        }

                        .Image {
                            aspect-ratio: 376 / 352;
                        }
                    }

                    &-Visual {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    &-PlayButtonWrapper {
                        padding-block: 0;
                    }

                    &-PlayButton {
                        position: absolute;
                        right: 0;
                        bottom: -10px;
                        z-index: 2;
                        width: auto;
                    }
                }
            }

            @include desktop {
                .PromoBanner-Title {
                    width: 420px;
                    max-width: 100%;
                }

                .PromoBanner-Content {
                    display: block;
                    margin-top: 40px;
                    padding-top: 0;
                    padding-inline-end: var(--content-wrapper-padding);
                }
            }

            @media (min-width: 1171px) {
                .PromoBanner-Content {
                    &::before {
                        right: inherit;
                        left: -350px;
                        z-index: -1;
                        bottom: inherit;
                        top: 50px;
                    }
                }
            }
        }

        &_fullWidth {
            .PromoBanner-Visual {
                .bannerVideoPlayer {
                    width: 100%;
                    // height: calc(100% - 50px) !important;
                }

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }

            @media (min-width: 811px) and (max-height: 600px) {
                // fix pour hauteur navigateur < 600px;
                max-height: 540px !important;
            }

            &:has(.bannerVideoPlayer) {
                overflow-x: hidden;
                position: relative;

                @media (max-width: 1170px) {
                    min-height: 846px !important;
                }

                @media (max-width: 810px) {
                    min-height: 429px !important;
                }

                .PromoBanner-Padding {
                    .PromoBanner-Figure {
                        position: relative;
                    }
                }

                .PromoBanner-Visual {
                    display: block;
                    height: 100%;
                    max-height: 100%;
                    width: 100%;

                    @media (min-width: 811px) {
                        height: calc(100% - 100px);
                    }

                    @media (min-width: 1170px) {
                        overflow-y: hidden;
                        height: calc(100% - 100px);
                    }

                    .PromoBanner-Figure {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: -99;
                        height: 100%;
                        max-height: 100%;

                        .video-foreground, iframe {
                            position: relative;
                            top: 0;
                            left: 0;
                            height: 100%;
                            pointer-events: none;
                            aspect-ratio: 16 / 9;
                            width: auto !important;

                            @media (max-width: 1170px) {
                                min-height: 706px !important;
                            }

                            @media (max-width: 810px) {
                                min-height: 429px !important;
                                max-height: 429px;
                            }

                            @media (min-width: 1170px) {
                                height: auto !important;
                                width: 100% !important;
                                min-width: 100%;
                            }
                        }

                        .video-foreground {
                            left: 50%;
                            transform: translateX(-50%);

                            @media (max-width: 1170px) {
                                padding-bottom: 50px;
                            }
                        }

                        iframe {
                            @media (max-width: 810px) {
                                min-height: 100% !important;
                                height: 100%;
                            }
                        }
                    }
                }

                .PromoBanner-Figure > div {
                    height: 100%;
                    width: 100%;
                }
            }

            @media (min-width: 811px) {
                //min-height: 846px;
                // aspect-ratio: 2.25;
                // max-height: 853px;

                .PromoBanner-Visual {
                    order: -1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                    padding: 0;
                    margin: 0;
                    // max-height: 853px;
                    // overflow: hidden;

                    .PromoBanner-Figure {
                        width: 100%;
                        height: 100%;
                    }

                    .Image-WidthFull {
                        aspect-ratio: auto !important;
                        height: 100%;

                        &.bannerVideoPlayer {
                            aspect-ratio: 16 / 9 !important;
                        }
                    }

                    // grid-column: none;
                }

                .PromoBanner-Content {
                    margin-left: 5em;
                }
            }

            @media (min-width: 1171px) {
                .PromoBanner-Content {
                    margin-left: 15em;
                }
            }

            .PromoBanner-Content {
                &::before {
                    display: none;
                }

                .PromoBanner-Title, .PromoBanner-Description {
                    color: var(--title-color-mobile, default);
                }

                &_hasShadow {
                    .PromoBanner-Title {
                        text-shadow: 0 0 12px rgba(0,0,0,.75);
                    }

                    .PromoBanner-Description {
                        text-shadow: 0 0 5px rgba(0,0,0,.75);
                    }
                }
            }
        }

        &_bottomRight {
            @media (min-width: 811px) and (max-width: 1170px) {
                display: grid;
                grid-template-columns: auto calc(50% - 20px)!important;
                grid-column-gap: 20px;

                .PromoBanner-Padding {
                    display: block;
                }

                .PromoBanner-Content {
                    padding: var(--content-wrapper-padding-mobile);
                }
            }
        }

        &_topLeft {
            // grid-auto-rows: auto;
            // grid-row: 2;
            // grid-column: 1 / 1;
            //max-height: 846px;

            @media (min-width: 811px) {
                max-height: 846px;
            }

            @media (min-width: 1171px) {
                grid-template-columns: auto 42.55% !important;
            }

            @include desktop {
                display: grid;
                grid-template-columns: calc(50% - 20px) 50%;
                grid-column-gap: 20px;
            }

            .PromoBanner-Content {
                order: 1;
                //grid-row: 1;
                //padding-bottom: 0;
                @include mobile {
                    padding: var(--content-wrapper-padding-mobile);
                    padding-block-start: 25px;
                    margin-top: 20px;
                    justify-content: flex-start;
                }
            }

            .PromoBanner-Padding {
                order: 2;
            }
        }

        @include desktop {
            display: grid;
            grid-template-columns: 50% calc(50% - 20px);
            grid-column-gap: 20px;
        }

        @media (min-width: 1171px) {
            grid-template-columns: 42.55% auto;
            grid-column-gap: 7%;
        }
    }

    &-AdditionalBlock {
        #html-body & { // to override page-builder style
            [data-element="inner"] {
                > [data-content-type="text"] {
                    @include desktop {
                        max-width: 475px;
                        margin-top: 20px;

                        p {
                            font-size: 21px;
                        }
                    }

                    @include mobile {
                        p {
                            font-size: 15px;
                        }
                    }
                }
            }

            .pagebuilder-column-group {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 6%;
                row-gap: 20px;
                max-width: 740px;

                @media (min-width: 1280px) {
                    margin-top: 50px;
                }

                @media (min-width: 1171px) {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include mobile {
                    margin-block: 10px 15px;
                    padding-inline: 10px;
                }
            }

            .pagebuilder-column {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include mobile {
                    display: grid;
                    grid-template-columns: 45px auto;
                    column-gap: 15px;

                    p {
                        font-size: 12px;
                    }

                    div:last-child {
                        grid-column: 2/3;

                        a {
                            display: inline-block;
                            vertical-align: top;
                        }
                    }
                }

                [data-content-type="image"] {
                    width: 55px;
                    height: 55px;
                    margin-block-end: 15px;
                    aspect-ratio: 1;

                    @include mobile {
                        grid-row: 1/4;
                        width: 100%;
                        height: 45px;
                        margin-block-end: 0;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                [data-content-type="text"] {
                    margin-block-end: 10px;

                    @include mobile {
                        margin-block-end: 5px;
                    }

                    em {
                        font-style: normal;

                        @include mobile {
                            display: none;
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-block-start: auto;
                        margin-block-end: 0;

                        a {
                            @include no-txt();

                            display: block;
                            height: 20px;
                            background: url($path-img + 'arrow-link-brown.svg') no-repeat left center;
                            background-size: 30px auto;
                            transition: all .3s ease;
                        }
                    }
                }
            }

            @include mobile {
                .pagebuilder-mobile-hidden {
                    display: none;
                }
            }

            @include desktop {
                .pagebuilder-mobile-only {
                    display: none;
                }
            }
        }
    }
}
