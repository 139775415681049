/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PromoBannerList {
    --content-wrapper-width: 100%;
    --content-wrapper-padding: 0;
    --content-wrapper-padding-mobile: 0;
    --banner-bg-color: transparent;

    .HomePage & {
        @include mobile {
            &:first-child {
                padding-block-start: 0 !important;
            }
        }
    }

    &-Content {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: var(--banner-bg-color);
            transition: background-color .5s ease-out;

            @include desktop {
                // height: calc(100% + var(--header-total-height));
                bottom: 78px;
            }

            @media (min-width: 1640px) {
                bottom: 100px;
            }
        }
    }

    &:first-child {
        .PromoBannerList-Content {
            &::before {
                @include desktop {
                    height: calc(100% + var(--header-total-height));
                }
            }
        }
    }

    &-Navigation {
        @include desktop {
            position: absolute;
            width: 170px;
            height: 35px;

            @media (min-width: 811px) {
                right: 0;
                bottom: 94px;
            }

            @media (min-width: 1171px) {
                bottom: 0px;
                right: 100px;
            }

            .swiper-button-prev {
                width: 20px;
                height: 20px;
                border: none;
                background: transparent;
                top: 0;
                margin-top: 0;
                z-index: 100;

                &::after {
                    font-size: 14px;
                }
            }

            .swiper-button-next {
                width: 20px;
                height: 20px;
                border: none;
                background: transparent;
                margin-top: 0;
                top: 0;

                &::after {
                    font-size: 14px;
                }
            }

            .swiper-pagination-bullet {
                height: 1px;
                border: none;
                width: 28px;
                margin: 0 !important;
                background: white;

                &.swiper-pagination-bullet-active {
                    &::after {
                        display: block;
                        content: "";
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        background: var(--primary-dark-color);
                    }
                }

                span {
                    font-size: 13px;
                    font-weight: 600;
                    position: absolute;
                    top: -20px;
                    left: 0;
                    text-align: 100%;
                    width: 28px;
                }
            }
        }

        @include mobile {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;

            .swiper-pagination-bullet {
                background: transparent;

                &-active {
                    background: var(--secondary-base-color);
                }

                span {
                    display: none;
                }
            }
        }

        /* @media (min-width: 810px) and (max-width: 1640px) {
            position: relative;
            right: inherit;
            bottom: inherit;
            margin-right: auto;
            margin-left: auto;
            margin-top: 40px;
        } */
    }

    &:has(.swiper-slide-active .bannerVideoPlayer) {
        .PromoBanner-Padding {
            pointer-events: none;
        }

        .PromoBanner-Content {
            pointer-events: none;

            a {
                pointer-events: initial;
            }
        }
    }
}

.ContentTopPromoBanner ~ .Router-MainItems {
    .HomePage {
        .PromoBannerList {
            &:first-child {
                padding-block-start: 0;
            }
        }
    }
}
